import axios from 'axios';
import getConfig from 'next/config';

const LOCALHOST = 'http://localhost:3000';

const {
  publicRuntimeConfig: {
    APP_VERSION,
    API_DONEDEAL_MAIN,
    API_DONEDEAL_SERVICES,
    API_DONEDEAL_AUTOCOMPLETE,
    API_INTERNAL,
    INTEGRATION_TESTING,
    ENVIRONMENT_NAME,
  },
} = getConfig();

export const baseHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  brand: 'donedeal',
  platform: 'web',
  version: APP_VERSION || '0',
};

/**
 * This function will swap a server-side gateway domain for the client hostname when axios gateway is used by the client
 * Will also set the correct top level domain (eg. co.uk)
 * @param serverDomain domain to be used on the server
 * @returns baseURL
 */
const swapOriginForClient = (
  serverDomain: string,
  isFEServer: boolean = false,
) => {
  if (INTEGRATION_TESTING) {
    return serverDomain;
  }

  if (typeof window !== 'undefined') {
    if (ENVIRONMENT_NAME === 'dev' || ENVIRONMENT_NAME === 'preview') {
      if (isFEServer && window?.location?.origin === LOCALHOST) {
        return LOCALHOST;
      }
      const clientDomain = 'https://www.donedealtest.com';
      return clientDomain;
    } else {
      return window.location.origin;
    }
  }
  return serverDomain;
};

export const donedealGateway = axios.create({
  baseURL: swapOriginForClient(API_DONEDEAL_MAIN),
  headers: { ...baseHeaders },
});

export const donedealAutoCompleteGateway = axios.create({
  baseURL: API_DONEDEAL_AUTOCOMPLETE,
  headers: { ...baseHeaders },
});

export const donedealServicesGateway = axios.create({
  baseURL: swapOriginForClient(API_DONEDEAL_SERVICES),
  headers: { ...baseHeaders },
});

/**
 * This gateway should be used for NextJS server routes - the `api` is already prepended
 */
export const frontendServerGateway = axios.create({
  baseURL: `${swapOriginForClient(API_INTERNAL, true)}/api`,
  headers: { ...baseHeaders },
});

export const donedealSearchAggGateway = axios.create({
  baseURL: API_DONEDEAL_MAIN,
  headers: { ...baseHeaders },
});

export const donedealServerGateway = axios.create({
  baseURL: swapOriginForClient(API_DONEDEAL_MAIN),
  headers: { ...baseHeaders },
});
