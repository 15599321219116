/**
 * This component exists to handle top level behaviour within the _app.tsx file that requires accessing context state from a top level provider.
 */
import { useEffect } from 'react';
import Cookies from 'universal-cookie';

import type { AppConsumerProps } from 'components/_App/_App.type';
/**
 * The following eslint rule is being ignored as this is the one place in the application where braze will be initialised client-side.
 * For all other braze event functions the `useBraze()` hook should be used which internally checks for allowBraze consent, here it is being checked directly before calling `brazeService()`
 */
// eslint-disable-next-line no-restricted-imports
import { brazeService } from 'services/brazeService';
import { useUserContext } from 'contexts/UserContext';
import { useAuthOptions } from 'hooks/useAuthOptions';
import { randomStringGenerator } from 'helpers/randomStringGenerator';
import { COOKIE, MAXIMUM_COOKIE_AGE } from 'helpers/constants';
import { getDomainFromBaseURL } from 'utils/CookieUtils';

const AppConsumer = (props: AppConsumerProps) => {
  const domain = getDomainFromBaseURL(props.domain);
  const {
    consentOptions: { allowBraze, functionalConsent },
    user,
  } = useUserContext();
  const { isAuthenticated } = useAuthOptions();
  /**
   * There are two conditions for invoking the Braze SDK
   * `allowBraze`: This is determined by the user's cookie consent to event tracking via Braze
   * `isAuthenticated`: Action taken to reduce MUV and avoid incurring excess costs, view [TP-95611](https://distilledsch.tpondemand.com/entity/95611-braze-event-management) for more context.
   *
   * If altering this value, the replicated value in `useBraze` should be updated accordingly
   */
  const CONDITIONS_TO_ENABLE_BRAZE = isAuthenticated && allowBraze;

  useEffect(() => {
    if (CONDITIONS_TO_ENABLE_BRAZE) brazeService();
  }, [CONDITIONS_TO_ENABLE_BRAZE]);

  useEffect(() => {
    const cookies = new Cookies();
    const clientID = cookies.get(COOKIE.DD_CLIENT_ID);
    const clientUID = cookies.get(COOKIE.DD_CLIENT_UID);

    if (functionalConsent && !clientID) {
      cookies.set(COOKIE.DD_CLIENT_ID, randomStringGenerator(), {
        maxAge: MAXIMUM_COOKIE_AGE,
        domain,
        path: '/',
      });
      if (user && !clientUID) {
        cookies.set(COOKIE.DD_CLIENT_UID, user.id.toString(), {
          maxAge: MAXIMUM_COOKIE_AGE,
          domain,
          path: '/',
        });
      }
    }
    if (!functionalConsent) {
      // remove cookie
      cookies.remove(COOKIE.DD_CLIENT_ID, { domain, path: '/' });
      cookies.remove(COOKIE.DD_CLIENT_UID, { domain, path: '/' });
    }
  }, [functionalConsent, user]);

  return <></>;
};

export { AppConsumer };
