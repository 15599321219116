import type { AxiosResponse, CancelTokenSource } from 'axios';
import type { IncomingHttpHeaders } from 'http';

import {
  donedealGateway,
  donedealServerGateway,
  donedealServicesGateway,
} from 'api/api-utils/commonApiUtils';
import type {
  verifyPhoneNumberResponse,
  confirmPhoneNumberResponse,
  GetUserResponse,
  GetAdCountsResponse,
  GetPromoteMyAdCopyResponse,
  IExitSurveyAnswers,
  IExitSurveyReason,
  SaveUnsaveAdResponse,
  PaymentHistoryData,
  IPageBoundaries,
  SellerAnalyticsData,
  SELLER_ANALYTICS_CATEGORIES,
  SELLER_ANALYTICS_TIME_RANGES,
  TGetPaymentOptions,
  TRemovePaymentOption,
  IUpdatePassword,
  INotificationPreferencesCategories,
  MyVehicleUpdateRequest,
  GetVehicleReportsResponse,
  IWhatsAppEnquiry,
} from 'api/types/accountsApiTypes';
import type { TPaymentCard } from 'types/orderTypes';

async function retrieveLegacySessionFromJWT(headers: IncomingHttpHeaders) {
  const response = await donedealServicesGateway.get(
    '/ddapi/legacy/accounts/identity/authenticate',
    { headers },
  );
  return response;
}

/**
 * This is a legacy API that includes a legacy redirect in its response headers
 */
async function logout() {
  const response = await donedealServerGateway.post(
    '/accounts/j_spring_security_logout',
  );
  return response;
}

export interface verifyPhoneNumber {
  phoneNumber: string;
}

async function verifyPhoneNumber(
  phoneNumber: verifyPhoneNumber,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<verifyPhoneNumberResponse>> {
  const response: AxiosResponse<verifyPhoneNumberResponse> =
    await donedealGateway.post(
      '/ddapi/legacy/accounts/api/v3/verification/phone/send',
      phoneNumber,
      {
        headers,
      },
    );
  return response;
}

interface confirmPhoneNumberPayload {
  authCode: string;
}

async function confirmPhoneNumber(
  authCode: confirmPhoneNumberPayload,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<confirmPhoneNumberResponse>> {
  const response: AxiosResponse<confirmPhoneNumberResponse> =
    await donedealGateway.post(
      '/ddapi/legacy/accounts/api/v3/verification/phone/confirm',
      authCode,
      { headers },
    );
  return response;
}

export type TUpdatePhone = (
  phoneNumber: verifyPhoneNumber,
  headers: IncomingHttpHeaders,
) => Promise<AxiosResponse<verifyPhoneNumberResponse>>;

async function updatePhone(
  phoneNumber: verifyPhoneNumber,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<verifyPhoneNumberResponse>> {
  const response: AxiosResponse<verifyPhoneNumberResponse> =
    await donedealGateway.post(
      '/ddapi/legacy/accounts/api/v3/dashboard/user/phone',
      phoneNumber,
      { headers },
    );
  return response;
}

async function resendEmail(
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<null>> {
  const response: AxiosResponse<null> = await donedealGateway.get(
    '/ddapi/legacy/accounts/api/v3/verification/email/resend',
    { headers },
  );
  return response;
}

async function getUser(
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<GetUserResponse>> {
  const response: AxiosResponse<GetUserResponse> = await donedealGateway.get(
    `/ddapi/v1/users/profile`,
    { headers },
  );
  return response;
}

async function updateUser(
  user: GetUserResponse,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<null>> {
  const response: AxiosResponse<null> = await donedealGateway.post(
    `/ddapi/v1/users/profile`,
    user,
    {
      headers,
    },
  );
  return response;
}

export type TRemoveStripeAccount = (
  headers: IncomingHttpHeaders,
) => Promise<AxiosResponse>;

async function removeStripeAccount(
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse> {
  const response: AxiosResponse = await donedealGateway.delete(
    `/ddapi/legacy/accounts/api/v3/stripe/disconnect`,
    { headers },
  );
  return response;
}

async function saveAd(
  id: number,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<SaveUnsaveAdResponse>> {
  const response: AxiosResponse<{ value: string }> = await donedealGateway.get(
    `/ddapi/legacy/cadview/api/v3/save/${id}`,
    { headers },
  );
  return response;
}

async function unsaveAd(
  id: number,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<SaveUnsaveAdResponse>> {
  const response: AxiosResponse<{ value: string }> = await donedealGateway.get(
    `/ddapi/legacy/cadview/api/v3/unsave/${id}`,
    { headers },
  );
  return response;
}

async function getAdCounts(
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<GetAdCountsResponse>> {
  const response: AxiosResponse<GetAdCountsResponse> =
    await donedealGateway.get(
      '/ddapi/legacy/accounts/api/v5/dashboard/counts',
      {
        headers: headers ? headers : {},
      },
    );
  return response;
}

async function getPromoteMyAdInfoModalCopy(
  cancelTokenSource: CancelTokenSource,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<GetPromoteMyAdCopyResponse>> {
  const response: AxiosResponse<GetPromoteMyAdCopyResponse> =
    await donedealGateway.get(`/ddapi/legacy/accounts/api/v4/dashboard/boost`, {
      headers: { accept: 'application/json', ...headers },
      cancelToken: cancelTokenSource.token,
    });
  return response;
}

async function getExitSurveyContent(
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<IExitSurveyReason[]>> {
  const response: AxiosResponse<IExitSurveyReason[]> =
    await donedealGateway.get('/ddapi/v2/ads/survey-options', {
      headers,
    });
  return response;
}

async function deleteAd(
  adId: number,
  headers: IncomingHttpHeaders,
  exitSurveyAnswers?: IExitSurveyAnswers,
): Promise<AxiosResponse<string>> {
  const data = exitSurveyAnswers;

  const response: AxiosResponse<string> = await donedealGateway.delete(
    `/ddapi/v2/ads/${adId}`,
    {
      headers,
      data,
    },
  );
  return response;
}

async function withdrawAd(
  id: number,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<string>> {
  const response: AxiosResponse<string> = await donedealGateway.delete(
    `/ddapi/v2/ads/withdraw/donedeal/${id}`, //TODO: remove site
    { headers },
  );

  return response;
}

async function relistAd(
  id: number,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<string>> {
  const response: AxiosResponse<string> = await donedealGateway.post(
    `/ddapi/v2/ads/relist/donedeal/${id}`,
    null,
    { headers },
  );

  return response;
}

async function setAdAsPending(
  id: number,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<{}>> {
  const response: AxiosResponse<{}> = await donedealGateway.post(
    `/ddapi/legacy/accounts/api/v3/dashboard/listings/setpending/${id}`,
    null,
    { headers },
  );

  return response;
}

async function saveUnsaveNewCardAd(
  save: boolean,
  make: string,
  model: string,
  bodyType: string,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<string>> {
  const response: AxiosResponse<string> = await donedealGateway.post(
    `/ddapi/legacy/accounts/api/v3/savedAds/savedNewCarAd/${save}`,
    {
      make,
      model,
      bodyType,
    },
    { headers },
  );
  return response;
}

async function getPaymentHistoryData(
  pageBoundaries: IPageBoundaries,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<PaymentHistoryData[]>> {
  const { limit, offset } = pageBoundaries;
  const response: AxiosResponse<PaymentHistoryData[]> =
    await donedealGateway.get(
      `/ddapi/legacy/accounts/api/v3/payments/all?limit=${limit}&offset=${offset}`,
      { headers },
    );
  return response;
}

async function getPaymentHistoryDocuments(
  ids: number[],
  document: 'email' | 'pdf',
  _headers: IncomingHttpHeaders,
): Promise<AxiosResponse<null | ArrayBuffer>> {
  const responseType = document === 'pdf' ? 'arraybuffer' : 'json';
  const headers =
    document === 'pdf'
      ? {
          accept: 'application/json, text/plain, */*',
        }
      : undefined;
  const response: AxiosResponse<null | ArrayBuffer> =
    await donedealGateway.post(
      `/ddapi/legacy/accounts/api/v3/payments/receipt/${document}`,
      {
        ids,
      },
      {
        headers: {
          ...headers,
          ..._headers,
        },
        responseType,
      },
    );
  return response;
}

async function getSellerAnalyticsData(
  userId: number,
  adId: number,
  name: SELLER_ANALYTICS_CATEGORIES,
  dataPeriod: SELLER_ANALYTICS_TIME_RANGES,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<SellerAnalyticsData>> {
  const response: AxiosResponse<SellerAnalyticsData> =
    await donedealGateway.post(
      `/ddapi/v1/listings/${adId}/analytics`,
      {
        name,
        dataPeriod,
      },
      {
        headers: {
          'X-Identity-UserId': userId,
          ...headers,
        },
      },
    );
  return response;
}

const getPaymentOptions: TGetPaymentOptions = async (headers) => {
  const response: AxiosResponse<TPaymentCard[]> = await donedealGateway.get(
    '/ddapi/v1/users/profile/payment-methods',
    { headers },
  );
  return response;
};

const removePaymentOption: TRemovePaymentOption = async (option, headers) => {
  const response: AxiosResponse<null> = await donedealGateway.delete(
    `/ddapi/v1/users/profile/payment-methods/${option}`,
    { headers },
  );
  return response;
};

async function updatePassword(
  { confirmPassword, confirmPassword2, password }: IUpdatePassword,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<null>> {
  const response: AxiosResponse<null> = await donedealGateway.post(
    '/ddapi/legacy/accounts/api/v3/dashboard/user/pass',
    {
      confirmPassword,
      confirmPassword2,
      password,
    },
    { headers },
  );
  return response;
}

async function getNotificationPreferences(
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<INotificationPreferencesCategories>> {
  const response = await donedealGateway.get(
    'ddapi/v1/users/notification/preferences',
    { headers },
  );
  return response;
}

async function updateNotificationPreference(
  category: string,
  subscriptionStatus: boolean,
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<null>> {
  const response = await donedealGateway.put(
    `ddapi/v1/users/notification/preferences/${category}`,
    { subscribed: subscriptionStatus },
    { headers },
  );
  return response;
}

const updateMyVehicle = async (
  vehicleData: MyVehicleUpdateRequest,
  headers: IncomingHttpHeaders,
) =>
  await donedealGateway.put('/ddapi/v1/users/profile/myvehicle', vehicleData, {
    headers,
  });

async function clearBrowsingData(
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<boolean>> {
  const response = await donedealGateway.delete(
    '/ddapi/legacy/accounts/api/v5/dashboard/listings/browsing-history/clear',
    { headers },
  );
  return response;
}

async function getWhatsAppLink(
  adId: number,
): Promise<AxiosResponse<IWhatsAppEnquiry>> {
  const response: AxiosResponse<IWhatsAppEnquiry> = await donedealGateway.get(
    `/ddapi/v1/listings/${adId}/whatsapp/enquiry`,
  );
  return response;
}

async function getVehicleReports(
  headers: IncomingHttpHeaders,
): Promise<AxiosResponse<GetVehicleReportsResponse>> {
  const response: AxiosResponse<GetVehicleReportsResponse> =
    await donedealGateway.get('/ddapi/v1/users/vehiclereports', {
      headers,
    });
  return response;
}

export const accountsApi = {
  retrieveLegacySessionFromJWT,
  logout,
  verifyPhoneNumber,
  confirmPhoneNumber,
  resendEmail,
  getUser,
  saveAd,
  unsaveAd,
  getAdCounts,
  getPromoteMyAdInfoModalCopy,
  withdrawAd,
  relistAd,
  setAdAsPending,
  saveUnsaveNewCardAd,
  getPaymentHistoryData,
  getPaymentHistoryDocuments,
  getSellerAnalyticsData,
  updatePhone,
  updateUser,
  removeStripeAccount,
  getPaymentOptions,
  removePaymentOption,
  updatePassword,
  getNotificationPreferences,
  updateNotificationPreference,
  updateMyVehicle,
  clearBrowsingData,
  getWhatsAppLink,
  getVehicleReports,
  getExitSurveyContent,
  deleteAd,
};
