import { rg4js } from 'helpers/raygun';

/**
 * Augment the global window object with a dataLayer array. This is used by Google Tag Manager to
 * track events.
 */
declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

type JSONValue =
  | string
  | number
  | boolean
  | { [x: string]: JSONValue }
  | Array<JSONValue>
  | null
  // Normally, JSON doesn't allow undefined, but in the GTM event we can use it since it will just
  // be ignored.
  | undefined;

export interface GTMEvent {
  /**
   * The event name. This should be included in all GTM events for tracking, but for type-safe
   * reasons it's not required because GTM will still work without it.
   */
  event?: string;

  /**
   * Any other data associated with the GTM event
   */
  [key: string]: JSONValue | undefined;
}

/**
 * This interface is provided for server-sent GTM objects which unfortunately were built with a bug
 * in them. The event field SHOULD be a string, but in some cases it's an array of strings.
 *
 * Since it's easier to change the UI here than a server API, we'll just note that it CAN happen and
 * let typescript help us handle it.
 *
 * We can remove this extra type when the server APIs are fixed. They were returning things like:
 *
 * {
 *   ... omitted ...
 *   dataTargetingProps: {
 *     dfp: IDFP;
 *     gtm: GTMEvent; <<< This should be GTMEvent, but sometimes it's GTMEventWithEventArray
 *   };
 *   ... omitted ...
 * }
 */
export interface GTMEventWithEventArray extends Omit<GTMEvent, 'event'> {
  /**
   * This is typed as a string | string[] so it will continue to work as the server API could be
   * fixed at some point - though since it'd be a breaking change, it's likely to require a
   * fresh version of the API.
   */
  event: string | string[];
}

/**
 * Pushes a Google Tag Manager (GTM) event to the data layer. This function is safe to call even if
 * the data layer is not available. These events will be picked up by GTM and sent to Google
 * Analytics.
 */
export function pushToDataLayer(data: GTMEvent): void;

/**
 * @deprecated please use the version of this function that takes a GTMEvent object instead.
 */
export function pushToDataLayer(data: GTMEventWithEventArray): void;

// This is the base function that accepts any type of GTM event.
export function pushToDataLayer(data: GTMEvent | GTMEventWithEventArray): void {
  const actualEvent =
    (data.event && (Array.isArray(data.event) ? data.event[0] : data.event)) ||
    undefined;
  if (Array.isArray(data.event) && data.event.length > 1) {
    rg4js('send', {
      error: new Error('GTM event has multiple events'),
      message: 'GTM event has multiple events. Only the first will be sent.',
      metadata: {
        data,
      },
    });
  }

  try {
    if (
      // If we're on the server-side, don't do anything here.
      typeof window !== 'undefined' &&
      'dataLayer' in window &&
      Array.isArray(window.dataLayer)
    ) {
      window.dataLayer.push({
        ...data,
        event: actualEvent,
        newPlatform: true,
      });
    }
  } catch (e) {
    rg4js('send', {
      error: e,
      message: 'Error while trying to push data layer',
      metadata: {
        data,
      },
    });
  }
}
