import { useEffect, useRef } from 'react';
import type { EffectCallback, DependencyList } from 'react';

/**
 * Source: https://github.com/thivi/use-non-initial-effect-hook/blob/master/src/use-non-initial-effect-hook.tsx
 * This hook gets called only when the dependencies change but not during initial render.
 *
 * @param {EffectCallback} effect The `useEffect` callback function.
 * @param {DependencyList} deps An array of dependencies.
 *
 * @example
 * ```
 *  useNonInitialEffect(()=>{
 *      alert("Dependency changed!");
 * },[dependency]);
 * ```
 */
function useOnUpdateOnly(effect: EffectCallback, deps?: DependencyList) {
  const initialRender = useRef(true);

  useEffect(() => {
    // @ts-ignore TEMP
    let effectReturns: void | (() => void | undefined) = () => {};

    if (initialRender.current) {
      initialRender.current = false;
    } else {
      // @ts-ignore TEMP
      effectReturns = effect();
    }

    if (effectReturns && typeof effectReturns === 'function') {
      return effectReturns;
    }
  }, deps);
}

export { useOnUpdateOnly };
