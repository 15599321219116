/**
 * The Raygun4JS client library to send tracking events to Raygun4JS for monitoring frontend performance and tracking errors / logs.
 *
 * @namespace rg4js
 *
 * @param {string} action - The action to perform, e.g., 'trackEvent'.
 * @param {any} type - The object of event, e.g., { metadata: { state: isLoading: true }}.
 *
 * @throws {Error} Throws an error if `rg4js` is not available in the window object.
 */

const rg4js = (action: string, customData: any) => {
  if ((window as any).rg4js) {
    (window as any).rg4js(action, customData);
  } else {
    console.error('rg4js is not available in the window object.');
  }
};

export { rg4js };
