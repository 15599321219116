import axios, { AxiosError } from 'axios';
import { PAGE, API } from 'helpers/pages';
import StatusCode from 'status-code-enum';
import { GetServerSidePropsContext } from 'next';

const cleanApiError = (axiosError: AxiosError) => ({
  api: {
    request: {
      url: axiosError?.config?.url,
      baseURL: axiosError?.config?.baseURL,
      method: axiosError?.config?.method,
    },
    response: axiosError?.response?.status
      ? {
          status: axiosError?.response?.status,
          statusText: axiosError?.response?.statusText,
        }
      : null,
  },
});

/** TODO:
 *  This function does not log errors, it simply formats them and should be renamed
 */
export const logServerError = (route: PAGE | API, error: any) => {
  if (axios.isAxiosError(error)) {
    return {
      route,
      ...cleanApiError(error),
    };
  } else {
    return {
      route,
      ...error,
      message: error.message || 'Unexpected server error',
    };
  }
};

export const logError = (
  ctx: GetServerSidePropsContext,
  error: any,
  page: PAGE,
) => {
  console.error(logServerError(page, error));

  if (error.code === 'ECONNABORTED') {
    // Timeout
    ctx.res.statusCode = StatusCode.ServerErrorGatewayTimeout;
  } else if (error?.response?.status === StatusCode.ClientErrorBadRequest) {
    // Bad request
    ctx.res.statusCode = StatusCode.ClientErrorBadRequest;
  } else {
    // Signal that there is an error
    ctx.res.statusCode = StatusCode.ServerErrorInternal;
  }
};
