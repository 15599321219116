import { SmartSessionStorage } from 'helpers/smartSessionStorage';
import { useEffect } from 'react';
import { rg4js } from 'helpers/raygun';
import { useRouter } from 'next/router';

const useRaygun = (raygunEnabled: boolean, enablePulsePercentage: number) => {
  const router = useRouter();

  const debugMode = Boolean(
    typeof window !== 'undefined' && SmartSessionStorage.get('debug') === true,
  );
  if (debugMode) console.log('Debug Mode Enable 🐞🐛️🛠️💻️️');

  if (enablePulsePercentage > 100 || enablePulsePercentage < 0) {
    console.error('Please provide a correct percentage between 0-100%');
  }

  if (typeof window !== 'undefined') {
    const pulseValueExistsForUser =
      SmartSessionStorage.get('enablePulse') !== null;
    if (!pulseValueExistsForUser) {
      const randomNumber = Math.random();
      if (randomNumber <= enablePulsePercentage / 100) {
        SmartSessionStorage.set('enablePulse', true);
      } else {
        SmartSessionStorage.set('enablePulse', false);
      }
    }
  }

  const enablePulse =
    typeof window !== 'undefined'
      ? Boolean(SmartSessionStorage.get('enablePulse') === true)
      : false;

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      rg4js('trackEvent', {
        type: 'pageView',
        path: url,
      });
    };

    raygunEnabled && router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      raygunEnabled && router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  return {
    enablePulse,
    debugMode,
  };
};

export { useRaygun };
