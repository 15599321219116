import { UrlObject } from 'url';
import {
  IGetSearchFilterMakeModel,
  ISearchPageFilterValue,
} from 'api/types/searchPageApiTypes';
import Link from 'next/link';
/**
 * UserCookieInfo
 * This is what we get from DDUS cookie set on DoneDeal.
 * It should not be used for any authentication requirements.
 * It should only be used for UI/UX
 */
import type { NextRouter } from 'next/router';
import type { ReactNode } from 'react';
import type { GoogleReviews } from 'components/PageWrappers/AdDetails/types/index';
import { IncomingHttpHeaders } from 'http';
import { GTMEvent } from 'services/gtmService';

export type ReturnTypeAsync<T extends (...args: any) => any> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

export interface UserCookieInfo {
  id: number;
  name: string;
  verification: { email: boolean; phone: boolean; allowedAccess: boolean };
  type: 'TRADER' | 'PRIVATE' | 'DEALER';
  termsVersionAccepted?: string;
  registrationdate: string;
}

export interface User extends UserCookieInfo {
  isMinimumVerified: boolean;
}

interface StripeInfo {
  state?: string;
  clientId?: string;
  accountId?: string;
}

export interface MyVehicle {
  notOwnVehicle: boolean;
  registrationNumber: string;
  insuranceRenewal: string;
  insuranceRenewalList: InsuranceRenewalList[];
  insuranceTargetable?: boolean;
}

export interface InsuranceRenewalList {
  displayText: string;
  value: string;
}

type MarketingPermissions = {
  email: boolean;
  notification: boolean;
  none: boolean;
};

type Consent = {
  acceptedTermsOfUse: boolean;
  recommendationsOptIn: boolean;
  marketingPermission: MarketingPermissions;
};

export interface IUserData {
  name: string;
  email: string;
  telephone: string;
  county: string;
  town: string;
  newsletter: boolean;
  passwordUpdateDate: string;
  trader: boolean;
  tradername: string;
  traderaddress: string;
  tradervatnumber: string;
  verification: {
    email: boolean;
    phone: boolean;
    allowedAccess: boolean;
    bank: boolean;
  };
  isVerifiableNumber: boolean;
  userId: string;
  consent: Consent;
  myVehicle: MyVehicle;
  stripeInfo?: StripeInfo;
  registrationdate?: string;
}

export interface PhotoViewSize {
  small: string;
  small2: string;
  medium: string;
  large: string;
  xLarge: string;
  smallWebp: string;
  small2Webp: string;
  mediumWebp: string;
  largeWebp: string;
  xLargeWebp: string;
}

export interface PhotoView {
  small: string;
  small2: string;
  medium: string;
  large: string;
  xLarge: string;
  smallWebp: string;
  small2Webp: string;
  mediumWebp: string;
  largeWebp: string;
  xLargeWebp: string;
  /** : The sort order of the photo */
  sortOrder?: number;
  /** The name of the photo */
  name?: string;
  /** : The id of the phot */
  id?: number;
}

export interface VideoConfig {
  videoProvider: 'YOUTUBE' | 'CLOUDINARY' | 'CITNOW';
  streamingUrl?: string;
  customProperties?: {
    thumbnail_url_3?: string;
    thumbnail_url: string;
    thumbnail_url_2?: string;
    thumbnail_url_1?: string;
    stream_url: string;
    thumbnail_url_0?: string;
  };
}

export interface ConsentOptions {
  functionalConsent: boolean;
  allowGoogle: boolean;
  allowWootric: boolean;
  allowBraze: boolean;
}

export interface AdSummary {
  id: number;
  userId?: number;
  state?: string;
  emailResponse?: boolean;
  phoneResponse?: boolean;
  bumpable?: boolean;
  spotlightable?: boolean;
  header: string;
  description?: string;
  price: string;
  oldPriceView?: IOldPrice;
  currency: CURRENCIES;
  keyInfo?: string[];
  age?: string;
  county?: string;
  financeSummary?: { price: string; financeProvider?: string };
  photos?: PhotoView[];
  userSaved?: boolean;
  spotlight?: boolean;
  friendlyUrl?: string;
  mediaCount?: number;
  dealer?: DealerSummary;
  manufacturerApprovedBadge?: IManufacturerApprovedBadge;
  meritsV2?: {
    field: string;
    text: string;
    description?: string;
    colour?: string;
  }[];
  herdwatchVerified?: boolean;
  specialOffer?: string;
  greenlightVerified?: boolean;
  cardPayments?: ICardPayment[];
  deliveryAvailable?: boolean;
  imageAlt?: string;
  wanted?: boolean;
  dealerId?: number;
  displayAttributes?: {
    name: string;
    displayName: string;
    value: string;
    badge?: { showBadge?: boolean };
  }[];
  section?: SectionView;
  year?: string;
  priceOnRequest?: boolean;
  countyTown?: string;
}

export interface PromoSlot {
  id: number;
  type: string;
  display: string;
  ads: AdSummary[];
  totalAdCount: number;
  showroomUrl: string;
  logoUrl: string;
  largeLogoUrl: string;
  dealerType: string;
  forecourtPhoto: {
    small: string;
    large: string;
    medium: string;
  };
}

export interface DealerSummary {
  id: number;
  address: string;
  enhancedAddress?: IBusinessAddress;
  description?: string;
  type?: string;
  openingHours?: string;
  enhancedOpeningHours?: EnhancedOpeningHours;
  longitude?: number;
  latitude?: number;
  websiteURL?: string;
  otherAds?: boolean;
  totalAds?: number;
  establishedYear?: number;
  franchiseType?: string;
  showMiniShowroom?: boolean;
  showDealerCard?: boolean;
  showEnquiryFormTradeIn?: boolean;
  showroomUrl: string;
  closedNow?: boolean;
  forecourtPhoto?: {
    small: string;
    large: string;
    medium: string;
  };
  name: string;
  logo?: {
    small?: string;
    medium?: string;
  };
  encryptedPhone?: string;
  franchisesDisplay?: string;
  franchiseCount?: number;
  level?: string;
  trustedDealer?: boolean;
  googleReview?: GoogleReviews;
}

export interface EnhancedOpeningHours {
  days: {
    day:
      | 'Monday'
      | 'Tuesday'
      | 'Wednesday'
      | 'Thursday'
      | 'Friday'
      | 'Saturday'
      | 'Sunday';
    openTime?: string;
    closeTime?: string;
    appointmentOnly?: boolean;
    appointmentOnlyText?: string;
  }[];
}

export interface SectionView {
  containsSubsections?: boolean;
  displayName?: string;
  name?: string;
  subdomain?: string;
  description?: string;
  id?: number;
  title?: string;
  xtn2?: number;
}

export interface GalleryUsageData {
  event: 'OPEN' | 'CLOSE';
  data?: {
    numberOfPhotos: number;
    numberOfPhotosViewed: number;
    videoImpression: boolean;
    threeSixtyImpression: boolean;
  };
}

export interface DataTargetingProps {
  gtm?: GTMEvent;
  dfp?: { [key: string]: string | [string] | [] | [null] };
}

export interface ISeoLinks {
  internalLinks: {
    text: string;
    url: string;
    make?: string;
    model?: string;
    year?: string;
    county?: string;
    fuelType?: string;
    sortKey?: number;
  }[];
}

// NB: types defined here should match 'next/link' component
export type Url = string | UrlObject;
export type LinkProps = {
  href: Url;
  as?: Url;
  replace?: boolean;
  scroll?: boolean;
  shallow?: boolean;
  passHref?: boolean;
  prefetch?: boolean;
  locale?: string | false;
};

export type NextLinkProps = typeof Link;
export interface SectionSummary {
  displayName: string;
  name: string;
  link?: string;
  query?: { [key: string]: string };
}

export interface DashLink {
  name: string;
  displayName: string;
  count: number;
  icon: string;
  url: string;
}

export interface LifestyleLinks {
  name: string;
  displayName: string;
  count: number;
  url: string;
}

interface ZESettings {
  webWidget: {
    zIndex: number;
  };
}

declare global {
  interface Window {
    zESettings?: ZESettings;
    zEmbed?: {
      show?: () => {};
      hide?: () => {};
    };
  }
}

export interface IFinanceBanner {
  title: string;
  buttonText: string;
  linkUrl: string;
  imageUrl: string;
  pricePerMonth: {
    priceDisplay: string;
    title: string;
    displayText: string; // TODO: Remove html
    buttonText: string;
    buttonUrl: string;
  };
  partnerType: string;
  partnerName: string;
}

export interface Notifications {
  totalUnread?: number;
  unreadHistoryChecks?: number;
  unreadMessages?: number;
}

export interface IUnreadInAppMessageData {
  id: number;
  notificationName: string;
  icon: string;
  type: string;
  title: string;
  message: string;
  ctaText: string;
  ctaUrl: string;
  ads?: IAMListing[];
  sellingPoint?: {
    displayText: string;
    icon?: string;
  };
}

export interface IDashboardAdCounts {
  searches: number;
  savedAds: { [key in SavedAdsListTypes]: number };
  payments: number;
  historyReports: number;
  myAds: { [key in MyAdsListTypes]: number };
  userId: number;
  conversations: number;
}

export interface DashboardAdData {
  daysRemaining?: number;
  seller?: IUserSummary;
  year?: string;
  county?: string;
  section: SectionView;
  photos?: IPhoto[];
  imageAlt?: string;
  videoConfig?: IVideoConfig;
  nativeAdParameters?: INativeAdParameters;
  link360Interior?: string;
  suggestedProductModal?: ISuggestedProductModal;
  meritsV2?: IMerits[];
  price?: string;
  mediaCount?: number;
  financeSummary?: IFinancePricePerMonthSearchModel;
  logo?: string;
  deliveryAvailable?: boolean;
  state?: string;
  specialOffer?: string;
  id: number;
  youTubeVideoId?: string;
  ManufacturerApprovedBadge?: IManufacturerApprovedBadge;
  wanted: boolean;
  ad360Details?: IAd360Details;
  campaign?: number;
  header: string;
  friendlyUrl?: string;
  numTimesSaved?: number;
  herdwatchVerified?: boolean;
  spotlightable?: boolean;
  description?: string;
  merits?: { [key: string]: any };
  dataTargetingProps?: DataTargetingProps;
  keyInfo?: Set<string>;
  displayAttributes?: IAdAttributeView[];
  cardPayments?: ICardPayment[];
  greenlightBadgeUrl?: string;
  oldPriceView?: IOldPrice;
  currency?: CURRENCIES;
  views?: number;
  greenlightVerified?: boolean;
  delivery?: IDelivery;
  boostInfoList?: IProductInfo[];
  cardPaymentsV2?: ICardPaymentsV2[];
  userSaved?: boolean;
  dealer?: IMotorDealer;
  relistInfoList?: IProductInfo[];
  spotlight?: boolean;
  link360?: string;
  age?: string;
  type?: string;
  historyCheckLink?: {
    mobileUrl: string;
    text: string;
    webUrl: string;
  };
  make?: string;
  model?: string;
  bodyType?: string;
  adAnalytics?: boolean;
  priceOnRequest?: boolean;
}

export interface IUserSummary {
  county?: string;
  adCountStats?: {
    activeAdView?: {
      displayText?: string;
      value?: string;
    };
    lifetimeAdView?: {
      displayText?: string;
      value?: string;
    };
  };
  adCount?: number;
  type?: string;
  countyTown?: string;
  otherAds?: boolean;
  registrationDate?: string;
  name: string;
  logo?: string;
  avgResponseRate?: string;
  id?: number;
  verification?: IUserVerification;
  traderName?: string;
}

export interface IProductInfo {
  header: string;
  lines?: string[];
  type?: string;
  price?: string;
  sellingPoints?: TSellingPoints[];
  badge?: { icon: string };
  displayName?: string;
}

type TSellingPoints = {
  title: string;
  description?: string;
};

export interface IBoostInfoList extends IProductInfo {
  contentShareUrl?: string;
  url?: string;
}

interface IPhoto {
  small?: string;
  small2?: string;
  xLarge?: string;
  smallWebp?: string;
  large?: string;
  largeWebp?: string;
  mediumWebp?: string;
  medium?: string;
  small2Webp?: string;
  xLargeWebp?: string;
  sortOrder?: number;
  name?: string;
  id?: number;
}

interface IVideoConfig {
  videoProvider?: string;
  customProperties?: { [key: string]: any };
}

interface INativeAdParameters {
  button2Url?: string;
  externalUrl?: string;
  button2Text?: string;
  background?: string;
  button1Text?: string;
  searchListingSubheader?: string;
  subtitle?: string;
  logo?: string;
  title?: string;
  button1Url?: string;
}

export interface ICardPayment {
  stripeAccountId?: string;
  quantity?: string;
  shippingFee?: string;
  shippingAvailable?: boolean;
  cardPaymentType?: string;
  acceptPayment?: boolean;
}

interface IDelivery {
  fee?: number;
  info?: string;
}

interface ICardPaymentsV2 {
  displayText?: string;
  amount?: number;
  cardPaymentType?: string;
  stripeAccountId?: string;
}

interface IUserVerification {
  allowedAccess?: boolean;
  bank?: boolean;
  phone?: boolean;
  email?: boolean;
}

export interface IOldPrice {
  oldPrice?: string | null;
  showOldPrice?: boolean;
}

export interface IMerits {
  description?: string;
  colour?: string;
  text?: string;
  field?: string;
}

interface IAd360Details {
  captureId?: string;
  adId?: number;
  thumbnailUrl?: string;
}

interface IManufacturerApprovedBadge {
  badgeUrl?: string;
  manufacturer?: string;
}

interface IFinancePricePerMonthSearchModel {
  financeProvider?: string;
  price?: string;
}

export interface ISuggestedProductModal {
  infoText?: string;
  footer?: ISuggestedProductModalFooter;
  titleText?: string;
  boldInfoText?: string;
  acceptText?: string;
  icon?: string;
  declineText?: string;
}

interface ISuggestedProductModalFooter {
  linkUrl?: string;
  preText?: string;
  mainText?: string;
  linkText?: string;
}

export interface IAdAttributeView {
  badge?: IBadge;
  displayName: string;
  link?: ILink;
  name: string;
  infoTip?: IInfoTip;
  value?: string;
}

interface IBadge {
  showBadge?: boolean;
  id?: number;
  displayName?: string;
}

interface ILink {
  action?: string;
  external?: boolean;
  title?: string;
  url?: string;
  dataTracking?: string;
}

interface IInfoTip {
  icon?: string;
  buttonText?: string;
  description?: string;
  title?: string;
  buttonLink?: string;
}

export interface IMotorDealer {
  showDealerCard?: boolean;
  enhancedAddress?: IBusinessAddress;
  latitude?: number;
  description: string;
  seoTitle?: string;
  type?: string;
  closedNow?: boolean;
  encryptedPhone: string;
  franchisesDisplay?: string;
  websiteURL?: string;
  logo?: { [key: string]: any };
  totalAds?: number;
  establishedYear?: number;
  id: number;
  showroomUrl?: string;
  longitude?: number;
  showMiniShowroom?: boolean;
  address?: string; // DEPRECATED
  seoDescription?: string;
  franchiseType?: string;
  otherAds?: boolean; // DEPRECATED
  enhancedOpeningHours?: EnhancedOpeningHours;
  forecourtPhoto?: { [key: string]: any };
  showEnquiryFormTradeIn?: boolean;
  franchiseCount?: number;
  name: string;
  openingHours?: string; // DEPRECATED
}

interface IBusinessAddress {
  addressLocality?: string;
  addressRegion?: string;
  streetAddress?: string;
  postalCode?: string;
}

export interface IPromoteMyAdCopy {
  title?: TitleGroup;
  paragraphs?: TextBlock[];
}

export type RemoveAdExitSurveyModalData = {
  id: number;
  description: string;
}[];

type TitleGroup = {
  icon?: string;
  title?: string;
  subtitle?: string;
};

export type TextBlock = {
  bulletPoints?: ListItem[];
  text?: string;
  title?: string;
  text2?: string;
};

type ListItem = {
  icon?: string;
  body?: string;
  title?: string;
};

export type PaginationEvent = {
  filterType: 'Pagination';
  from: number;
  pageSize: number;
  searchQueryGroup: string;
  startTime?: number | null;
};

export type Paging = {
  currentPage: number;
  totalPages: number;
  totalResults: number;
  onUpdate: (event: PaginationEvent) => Promise<void> | void;
  pageSize: number;
  searchQueryGroup?: string;
  startTime?: number | null;
};

export type ListTypes =
  | MyAdsListTypes
  | SavedAdsListTypes
  | BrowsingHistoryTypes;

type MyAdsListTypes = LIST_NAMES.ALL | LIST_NAMES.LISTED | LIST_NAMES.UNLISTED;

type SavedAdsListTypes =
  | LIST_NAMES.ALL
  | LIST_NAMES.ACTIVE
  | LIST_NAMES.INACTIVE;

type BrowsingHistoryTypes =
  | LIST_NAMES.RECENTLY_VIEWED_ADS
  | LIST_NAMES.RECOMMENDED_ADS;

export enum CURRENCIES {
  EUR = 'EUR',
  GBP = 'GBP',
}

export type Currency = keyof typeof CURRENCIES;

export enum LIST_NAMES {
  ALL = 'all',
  LISTED = 'listed',
  UNLISTED = 'unlisted',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  RECENTLY_VIEWED_ADS = 'recently-viewed-ads',
  RECOMMENDED_ADS = 'recommended-ads',
}

export type dashboardAdsRequestTypes = keyof typeof DASHBOARD_AD_CATEGRORIES;

export const DASHBOARD_AD_CATEGRORIES = {
  ALL: 'ALL',
  LISTED: 'LISTED',
  UNLISTED: 'UNLISTED',
} as const;

export enum GTM_PAGES {
  AD_DETAILS = 'adDetails',
  DASHBOARD_MY_ADS = 'dashboard-my-ads',
}

export interface ListingsPage {
  baseUrl: string;
  pageSize: number;
  cdnUrl: string;
  router: NextRouter;
  offset: number;
  setOffset: (t: number) => void;
  pageNumber: number;
  setPageNumber: (t: number) => void;
  selectedTab: ListTypes;
  setSelectedTab: (t: ListTypes) => void;
  token?: string;
}

export interface IAMListing extends AdSummary {
  adViews?: number;
  daysLive: number;
}

export interface SavedSearch {
  frequency: string;
  name: string;
  description: string;
  search: ISearchRequest;
  id?: number;
}

interface ISearchRequest {
  decayFunctions?: (
    | {
        decayFunction: 'LINEAR_PRICE';
        value: {};
      }
    | undefined
  )[];
  filters?: (IFilter | undefined)[];
  geoFilter?: GeoFilter;
  makeModelFilters?: {
    make: string;
    model: string;
    trim?: string;
  }[];
  notFilters?: (IFilter | undefined)[];
  paging?: {
    from: number;
    pageSize: number;
  };
  ranges?: (IRange | undefined)[];
  scoringFilters?: (IFilter | undefined)[];
  scoringRanges?: (IRange | undefined)[];
  sections?: string[];
  sort?: string;
  startTime?: number;
  terms?: string;
}

export type GeoFilter = {
  lat: number;
  lon: number;
  rad: number;
  countyTown: string | null;
  county: string | null;
};

interface IFilter {
  name: string;
  values: string[];
}

interface IRange {
  to?: string;
  from?: string;
  name: string;
}

export interface IAdSenseUnit {
  container: string;
  number?: number;
  lines?: number;
  width?: number;
  height?: number;
  relatedSearches?: number;
  detailedAttribution?: boolean;
  disableCarousel?: boolean;
  adLoadedCallback?: (container: string, success: boolean) => void;
}

export type ResponseMakeModelFilterValue = {
  all: ISearchPageFilterValue[];
  popular: ISearchPageFilterValue[];
  valueType: string;
};

export type ResponseMakeModelType = {
  make: string;
  model: string;
  trim: string;
}[];

export type ResponseModelFilter = ({
  makeName: string;
} & IGetSearchFilterMakeModel)[];

export type UrlParamType = string | string[];
export type UrlParamTypeOptional = UrlParamType | undefined;

export interface IQueryParams {
  [key: string]: UrlParamTypeOptional;
}

export enum AD_SENSE_TYPES {
  SEARCH = 'ads',
}

export enum DASHBOARD_PAGE_TYPES {
  MY_DONEDEAL = 'mydonedeal',
  LISTINGS = 'listings',
  MESSAGES = 'messages',
  SAVED = 'saved',
  SEARCHES = 'searches',
  HISTORY_CHECKS = 'history-checks',
  PAYMENTS = 'payments',
  BROWSING_HISTORY = 'browsing-history',
  //TODO: Remove post messages rollout
  NEW = 'new',
}

export interface IPaymentHistoryData {
  ad: DashboardAdData;
  amount: number;
  currency: CURRENCIES;
  paymentDate: string;
  paymentId: number;
}

export interface GetAdCounts {
  searches: number;
  savedAds: { [key in SavedAdsListTypes]: number };
  payments: number;
  historyReports: number;
  myAds: { [key in MyAdsListTypes]: number };
  userId: number;
  conversations: number;
}

export interface IPageBoundaries {
  limit: number;
  offset: number;
}

export interface BoostModalData {
  boostInfoList: IUpsellsData[];
  bumpEligible?: boolean;
  spotlightEligible?: boolean;
  section?: string;
}

export type PlotValue = {
  date: string;
  endDate?: string;
  value: number;
  bumps: number;
  spots: number;
  priceDrops: number;
};

export type SellerAnalyticsModalDisplayType = {
  title?: string | ReactNode;
  selectedTab: SELLER_ANALYTICS_CATEGORIES;
  data: PlotValue[];
};

export enum SELLER_ANALYTICS_CATEGORIES {
  AD_VIEWS = 'adViews',
  SAVES = 'saves',
  LEADS = 'leads',
}

export enum SELLER_ANALYTICS_TIME_RANGES {
  SEVEN_DAYS = '7',
  THIRTY_DAYS = '30',
  NINETY_DAYS = '90',
}

export enum SELLER_ANALYTICS_TIME_RANGE_DISPLAY_TEXT {
  LAST_7_DAYS = 'Last 7 days',
  LAST_30_DAYS = 'Last 30 days',
  LAST_90_DAYS = 'Last 90 days',
}

export enum SELLER_ANALYTICS_CATEGORY_DISPLAY_TEXT {
  VIEWS = 'Views',
  SAVES = 'Saves',
  LEADS = 'Leads',
}

export enum MILEAGE_UNIT {
  KILOMETRES = 'Kilometres',
  MILES = 'Miles',
}

export type InitialEnquiryModalValues = {
  contactName: string;
  contactNo?: string;
  email: string;
  message: string;
  showInterestOptions: boolean;
  financingVehicle?: boolean;
  schedulingTestDrive?: boolean;
  vehicleTradeIn?: boolean;
  vehicleCondition?: boolean;
  registrationNo?: string;
  make?: string;
  model?: string;
  year?: string;
  engine?: string;
  numDoors?: string;
  bodyType?: string;
  fuelType?: string;
  transmission?: string;
  nct?: string;
  owners?: string;
  mileage?: string;
  mileageType?: MILEAGE_UNIT.KILOMETRES | MILEAGE_UNIT.MILES;
  saveEnquiry?: boolean;
};

export interface IBundle {
  id: number;
  name: string;
  display?: string;
  price: number;
  sterlingPrice: number;
  displayName: string;
  sellingPoints: ISellingPoint[];
  maxPhotoCount: number;
  durationSummary: string;
  includedProducts?: IIncludedProduct[];
  suggestedProducts?: ISuggestedProduct[];
  optionalProducts?: IOptionalProduct[];
  viewsCount: number;
  republishUrl?: string;
  isRecommended?: boolean;
  displayPrice?: string;
  paymentUrl?: string;
}

interface IModal {
  icon: string;
  titleText: string;
  boldInfoText: string;
  infoText: string;
  acceptText: string;
  declineText: string;
  footer: {
    preText: string;
    mainText: string;
    linkText: string;
    linkUrl: string;
  };
  id?: number;
}

interface ISellingPoint {
  name?: string;
  title: string;
  description?: string;
  toolTip?: {
    name?: string;
    title: string;
    description: string;
  };
}

interface IIncludedProduct {
  id: number;
  name: string;
  display?: string;
  price: number;
  sterlingPrice: number;
}

interface ISuggestedProduct extends IIncludedProduct {
  modal: IModal;
}

export interface IOptionalProduct {
  id: number;
  name: string;
  display?: string;
  price: number;
  sterlingPrice: number;
  displayName: string;
  maxPhotoCount: number;
  durationSummary: string;
  viewsCount: number;
  republishUrl?: string;
}

export interface IUpsellsData {
  id?: number;
  type: string;
  displayName: string;
  name?: string;
  price: string;
  contentShareUrl?: string;
  sellingPoints: ISellingPoint[];
  viewsCount: number;
  title: string;
  suggestedProductModalID?: number;
}

export enum BUNDLE_NAMES {
  BASIC = 'Basic',
  BUMP = 'Bump',
  SPOTLIGHT = 'Spotlight',
  LITE = 'Lite',
  STANDARD = 'Standard',
  PREMIUM = 'Premium',
}

export interface IRepublishModalData {
  bundles: IBundle[];
  metaData: {
    arrayLength: number;
  };
}

export interface INotificationPreferencesCategories {
  newsAndOffers: boolean;
  savedSearch: boolean;
  sellingTips: boolean;
  recommendations: boolean;
  savedAds: boolean;
}

export type TAutoCompleteSearchRequestMakeModelFilters = {
  make: string;
  model?: string;
};

export type TAutoCompleteSearchRequest = {
  sections: string[];
  terms?: string;
  makeModelFilters?: TAutoCompleteSearchRequestMakeModelFilters[];
  filters?: {
    name: string;
    values: string[];
  }[];
};

export interface IAutoCompleteSuggestion {
  leftText?: string;
  rightText: string;
  url: string;
  searchRequest: TAutoCompleteSearchRequest;
  rightTextMuted?: string;
}

export type TInputOptions = { value: string; label: string };

export type TCustomHeaders = IncomingHttpHeaders;

export type AuthHeader = {
  Authorization?: string;
};
