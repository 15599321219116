import type { NextPageContext, GetServerSidePropsContext } from 'next';

/**
 * @param name Name of the cookie
 * @param ctx NextPageContext
 * @param shouldJsonParse Set to true if Cookie contains valid stringified JSON
 */
function getServerCookie(
  name: string,
  ctx?: NextPageContext | GetServerSidePropsContext,
  shouldJsonParse?: boolean,
) {
  const cookie = ctx?.req?.headers?.cookie;
  if (cookie) {
    const cookieName = `${name}=`;
    const decodedCookie = decodeURIComponent(cookie);
    const cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
      let current = cookieArray[i];
      while (current.charAt(0) === ' ') {
        current = current.substring(1);
      }
      if (current.indexOf(cookieName) === 0) {
        const cookieS = current.substring(cookieName.length, current.length);
        if (shouldJsonParse) {
          try {
            return JSON.parse(cookieS);
          } catch (_error) {
            return cookieS;
          }
        }
        return cookieS;
      }
    }
  }
  return undefined;
}

type CookieOptions = {
  domain?: string;
  path?: string;
  expiry?: string;
  httpOnly?: boolean;
  secure?: boolean;
  maxAge?: number | 'session';
};

function formatCookie(
  name: string,
  value: string,
  options: CookieOptions = {},
) {
  const {
    domain,
    expiry,
    path = '/',
    httpOnly = true,
    secure = true,
    maxAge,
  } = options;
  let cookieStr = `${name}=${value}; path=${path}; `;
  if (domain) cookieStr += `Domain=${domain}; `;
  if (httpOnly) cookieStr += `HttpOnly; `;
  if (secure) cookieStr += `Secure; `;
  if (expiry) cookieStr += `Expiry=${expiry}; `;
  if (maxAge) cookieStr += `Max-Age=${maxAge}; `;
  return cookieStr.slice(0, -2);
}

function getDomainFromBaseURL(baseURL: string): string {
  if (baseURL === 'http://localhost:3000') return 'localhost';
  if (baseURL.includes('dsch.build')) return '.dsch.build';
  return baseURL.replace('https://', '').replace('www', '');
}

export { getServerCookie, formatCookie, getDomainFromBaseURL };
