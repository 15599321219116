import {
  createContext,
  useReducer,
  useContext,
  useState,
  useEffect,
} from 'react';

import type { Dispatch, ReactNode } from 'react';
import type {
  User,
  IUnreadInAppMessageData,
  ISuggestedProductModal,
  BoostModalData,
  SellerAnalyticsModalDisplayType,
  ConsentOptions,
} from 'types';
import type { UserReducerAction } from 'contexts/reducers/userReducer';

import { userReducer } from 'contexts/reducers/userReducer';
import { useRouter } from 'next/router';
import { useAuthOptions } from 'hooks/useAuthOptions';
import { NotificationFrequency } from 'components/SearchPage/hooks/useSavedSearch';

interface UserProviderProps {
  children: ReactNode;
  initialUser: User | null | undefined;
  ddRecommendedUserValue?: string | number;
  consentOptions: ConsentOptions;
}

interface IHistoryCheckModalData {
  title?: string;
}

interface UserContextI {
  user: User | undefined;
  dispatchUser: Dispatch<UserReducerAction>;
  isShareModalOpen: boolean;
  setIsShareModalOpen: (t: boolean) => void;
  consentOptions: {
    functionalConsent: boolean;
    allowGoogle: boolean;
    allowWootric: boolean;
    allowBraze: boolean;
  };
  ddRecommendedUser?: string | number;
  setddRecommendedUser: (t: string | number) => void;
  isInAppMessageSimpleModalOpen: boolean;
  setIsInAppMessageSimpleModalOpen: (t: boolean) => void;
  inAppMessageData?: IUnreadInAppMessageData;
  setInAppMessageData: (t: IUnreadInAppMessageData | undefined) => void;
  isBoostModalOpen: boolean;
  setIsBoostModalOpen: (t: boolean) => void;
  boostModalData: BoostModalData | undefined;
  setBoostModalData: (t: BoostModalData | undefined) => void;
  isSuggestedProductModalOpen: boolean;
  setIsSuggestedProductModalOpen: (t: boolean) => void;
  suggestedProductModalData?: ISuggestedProductModal;
  setSuggestedProductModalData: (t: ISuggestedProductModal | undefined) => void;
  currency: 'EUR' | 'GBP' | undefined;
  setCurrency: (t: 'EUR' | 'GBP' | undefined) => void;
  selectedBundle?: number;
  setSelectedBundle: (bundleID: number) => void;
  isHistoryCheckModalOpen: boolean;
  setIsHistoryCheckModalOpen: (t: boolean) => void;
  historyCheckModalData: IHistoryCheckModalData | undefined;
  setHistoryCheckModalData: (t: IHistoryCheckModalData | undefined) => void;
  isSavedSearchModalOpen: boolean;
  setIsSavedSearchModalOpen: (t: boolean) => void;
  isInstantAlertAvailable: boolean;
  setIsInstantAlertAvailable: (t: boolean) => void;
  isSellerAnalyticsModalOpen: boolean;
  setIsSellerAnalyticsModalOpen: (t: boolean) => void;
  sellerAnalyticsModalDisplay?: SellerAnalyticsModalDisplayType;
  setSellerAnalyticsModalDisplay: (
    t: SellerAnalyticsModalDisplayType | undefined,
  ) => void;
  isTrustedDealerModalOpen: boolean;
  setIsTrustedDealerModalOpen: (t: boolean) => void;
  isSearchSaved: boolean;
  setIsSearchSaved: (saved: boolean) => void;
  selectedNotificationFrequency?: NotificationFrequency;
  setSelectedNotificationFrequency: (frequency: NotificationFrequency) => void;
}

const UserContext = createContext<UserContextI>({
  user: undefined,
  dispatchUser: () => null,
  isShareModalOpen: false,
  setIsShareModalOpen: () => null,
  ddRecommendedUser: undefined,
  setddRecommendedUser: () => null,
  isInAppMessageSimpleModalOpen: false,
  setIsInAppMessageSimpleModalOpen: () => null,
  inAppMessageData: undefined,
  setInAppMessageData: () => null,
  isBoostModalOpen: false,
  setIsBoostModalOpen: () => null,
  boostModalData: undefined,
  setBoostModalData: () => null,
  isSuggestedProductModalOpen: false,
  setIsSuggestedProductModalOpen: () => null,
  suggestedProductModalData: undefined,
  setSuggestedProductModalData: () => null,
  currency: undefined,
  setCurrency: () => null,
  selectedBundle: undefined,
  setSelectedBundle: () => null,
  isHistoryCheckModalOpen: false,
  setIsHistoryCheckModalOpen: () => null,
  historyCheckModalData: undefined,
  setHistoryCheckModalData: () => null,
  isSavedSearchModalOpen: false,
  setIsSavedSearchModalOpen: () => null,
  isInstantAlertAvailable: false,
  setIsInstantAlertAvailable: () => null,
  isSellerAnalyticsModalOpen: false,
  setIsSellerAnalyticsModalOpen: () => null,
  sellerAnalyticsModalDisplay: undefined,
  setSellerAnalyticsModalDisplay: () => null,
  isTrustedDealerModalOpen: false,
  setIsTrustedDealerModalOpen: () => null,
  consentOptions: {
    functionalConsent: false,
    allowGoogle: false,
    allowWootric: false,
    allowBraze: false,
  },
  isSearchSaved: false,
  setIsSearchSaved: () => null,
  selectedNotificationFrequency: undefined,
  setSelectedNotificationFrequency: () => null,
});

function UserProvider({
  children,
  initialUser,
  ddRecommendedUserValue,
  consentOptions,
}: UserProviderProps) {
  function init(initialUser: User | undefined) {
    if (initialUser) {
      return {
        ...initialUser,
        isMinimumVerified:
          initialUser?.verification?.email || initialUser?.verification?.phone,
      };
    }
  }

  const [user, dispatchUser] = useReducer(userReducer, initialUser, init);
  const router = useRouter();
  const { handleLogout, status } = useAuthOptions();

  useEffect(() => {
    if (user?.type === 'DEALER' && status === 'authenticated') {
      // if the page is authenticated it will redirect to home page for us
      handleLogout(router.asPath, user);
    }
  }, [user, status]);

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [ddRecommendedUser, setddRecommendedUser] = useState(
    ddRecommendedUserValue,
  );
  const [isInAppMessageSimpleModalOpen, setIsInAppMessageSimpleModalOpen] =
    useState(false);
  const [inAppMessageData, setInAppMessageData] =
    useState<IUnreadInAppMessageData>();
  const [isBoostModalOpen, setIsBoostModalOpen] = useState<boolean>(false);
  const [boostModalData, setBoostModalData] = useState<
    BoostModalData | undefined
  >(undefined);
  const [isSuggestedProductModalOpen, setIsSuggestedProductModalOpen] =
    useState<boolean>(false);
  const [suggestedProductModalData, setSuggestedProductModalData] = useState<
    ISuggestedProductModal | undefined
  >(undefined);
  const [currency, setCurrency] = useState<'EUR' | 'GBP' | undefined>(
    undefined,
  );
  const [selectedBundle, setSelectedBundle] = useState<number | undefined>(
    undefined,
  );
  const [isHistoryCheckModalOpen, setIsHistoryCheckModalOpen] =
    useState<boolean>(false);
  const [historyCheckModalData, setHistoryCheckModalData] = useState<
    IHistoryCheckModalData | undefined
  >(undefined);
  const [isSavedSearchModalOpen, setIsSavedSearchModalOpen] =
    useState<boolean>(false);
  const [isInstantAlertAvailable, setIsInstantAlertAvailable] =
    useState<boolean>(false);
  const [isSellerAnalyticsModalOpen, setIsSellerAnalyticsModalOpen] =
    useState<boolean>(false);
  const [sellerAnalyticsModalDisplay, setSellerAnalyticsModalDisplay] =
    useState<SellerAnalyticsModalDisplayType | undefined>(undefined);
  const [isTrustedDealerModalOpen, setIsTrustedDealerModalOpen] =
    useState<boolean>(false);
  const [isSearchSaved, setIsSearchSaved] = useState<boolean>(false);
  const [selectedNotificationFrequency, setSelectedNotificationFrequency] =
    useState<NotificationFrequency | undefined>();

  const shouldReturnUser =
    (user?.type === 'DEALER' && router.pathname.startsWith('/dealerhub/')) ||
    user?.type !== 'DEALER';

  return (
    <UserContext.Provider
      value={{
        user: shouldReturnUser ? user : undefined,
        dispatchUser,
        isShareModalOpen,
        setIsShareModalOpen,
        ddRecommendedUser,
        setddRecommendedUser,
        isInAppMessageSimpleModalOpen,
        setIsInAppMessageSimpleModalOpen,
        inAppMessageData,
        setInAppMessageData,
        isBoostModalOpen,
        setIsBoostModalOpen,
        boostModalData,
        setBoostModalData,
        isSuggestedProductModalOpen,
        setIsSuggestedProductModalOpen,
        suggestedProductModalData,
        setSuggestedProductModalData,
        currency,
        setCurrency,
        selectedBundle,
        setSelectedBundle,
        isHistoryCheckModalOpen,
        setIsHistoryCheckModalOpen,
        historyCheckModalData,
        setHistoryCheckModalData,
        isSavedSearchModalOpen,
        setIsSavedSearchModalOpen,
        isInstantAlertAvailable,
        setIsInstantAlertAvailable,
        isSellerAnalyticsModalOpen,
        setIsSellerAnalyticsModalOpen,
        sellerAnalyticsModalDisplay,
        setSellerAnalyticsModalDisplay,
        isTrustedDealerModalOpen,
        setIsTrustedDealerModalOpen,
        consentOptions,
        isSearchSaved,
        setIsSearchSaved,
        selectedNotificationFrequency,
        setSelectedNotificationFrequency,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

const useUserContext = () => useContext(UserContext);

export { UserProvider, useUserContext };
